<template>
  <!-- 飘窗效果 -->
  <div id="thediv"
       ref="thediv"
       v-show="thedivShow"
       @mouseover="clearFdAd"
       @mouseout="starFdAd">

    <div v-if="adPictureWindow.jumpway !== '02'">
      <img :src="adPictureWindow.picUrl"
           alt="">
      <div style="
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        text-align: right;
        font-size: 12px;
        color: #999999;
      "
           @click="thedivShow = false">
        <img src="../../assets/indexImg/closed.gif"
             alt="">
      </div>
    </div>
    <a v-else :href="adPictureWindow.picDesc"
       target="_blank">
       <img :src="adPictureWindow.picUrl"
           alt="">
      <div style="
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        text-align: right;
        font-size: 12px;
        color: #999999;
      "
           @click="thedivShow = false">
        <img src="../../assets/indexImg/closed.gif"
             alt="">
      </div>
    </a>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    adPictureWindow: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      //定义相关参数
      xPos: 0,
      yPos: 0,
      xin: true,
      yin: true,
      step: 3,
      delay: 18,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true,
      thedivShow: true,
      interval: ''
    };
  },

  created() {
    // this.callLabList(); //调用执行
  },
  mounted() {
    this.interval = setInterval(this.changePos, this.delay);
  },
  methods: {
    //滚动逻辑方法
    changePos() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (this.$refs.thediv) {
        this.Hoffset = this.$refs.thediv.offsetHeight; //获取元素高度
        this.Woffset = this.$refs.thediv.offsetWidth;
        // 滚动部分不随屏幕滚动
        this.$refs.thediv.style.left = this.xPos + document.body.scrollLeft + 'px';
        this.$refs.thediv.style.top = this.yPos + document.body.scrollTop + 'px';

        if (this.yon) {
          this.yPos = this.yPos + this.step;
        } else {
          this.yPos = this.yPos - this.step;
        }
        if (this.yPos < 0) {
          this.yon = 1;
          this.yPos = 0;
        }
        if (this.yPos >= height - this.Hoffset) {
          this.yon = 0;
          this.yPos = height - this.Hoffset;
        }

        if (this.xon) {
          this.xPos = this.xPos + this.step;
        } else {
          this.xPos = this.xPos - this.step;
        }
        if (this.xPos < 0) {
          this.xon = 1;
          this.xPos = 0;
        }
        if (this.xPos >= width - this.Woffset) {
          this.xon = 0;
          this.xPos = width - this.Woffset;
        }
      }
      // 滚动时跟随屏幕滚动 需要时解开注释
      // this.$refs.thediv.style.left = (this.xPos +
      //document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
      // this.$refs.thediv.style.top = (this.yPos +
      //document.body.scrollTop + document.documentElement.scrollTop) + "px";
    },
    clearFdAd() {
      clearInterval(this.interval);
    },
    starFdAd() {
      this.interval = setInterval(this.changePos, this.delay);
    }
  }
};
</script>
<style>
/* //@import url(); 引入公共css类 */
#thediv {
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;
}
</style>