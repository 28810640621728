<template>
  <div>
    <pcHeaders></pcHeaders>

    <div style="position: relative;">
      <el-carousel :interval="5000"
                   arrow="always"
                   height="400px">
        <el-carousel-item v-for="item in carouselList"
                          :key="item.id">
          <img :src="item.picUrl"
               style="position: relative;z-index: 999;width: 100%;"
               @click="toGetPicMessage(item)"
               alt="">
        </el-carousel-item>
      </el-carousel>
      <div class="pos">
        <div class="newsBox">
          <div class="flex"
               style="justify-content: space-between;padding:  0 16px;">
            <div style="font-size: 18px;">通知公告</div>
            <div class="flex"
                 style="cursor: pointer;;color: #999999;font-size: 14px;align-items: center;"
                 @click="more">
              <div style="margin-right: 5px;">更多</div>
              <div><img src="../assets/images/arrow_right.png"
                     alt=""></div>
            </div>
          </div>

          <div class="newList flex"
               style="align-items: center;"
               v-for="item in tzggList"
               :key="item.ID"
               @click="tzgg(item)">
            <div>
              <img style="margin-top: 4px;"
                   src="../assets/indexImg/icon_bell.png"
                   alt="">
            </div>
            <div class="listText">
              {{ item.TITLE }}
            </div>
          </div>
          <router-link to="/xcx">
            <div class="botmBtn">
              查看小程序和移动端
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="banner">
      <div style="width: 1200px;">
        <div class="fastTrack">
          <div class="text">
            <img src="../assets/indexImg/title_lf.png"
                 alt="">
            <span>快速</span>通道
            <img src="../assets/indexImg/title_rgt.png"
                 alt="">
          </div>
        </div>

        <div class="fastList">

          <div class="box1"
               @click="goList('0702')">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;"
                 src="../assets/indexImg/step2.png"
                 alt="">

            <div class="textBox">
              <div>专业课</div>
              <div class="bc">

              </div>
              <div class="section-content">
                学习新理论、新技术、新方法,提高创新能力和专业技术水平。
              </div>
            </div>

          </div>
          <div class="box1"
               @click="goList('0703')">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;"
                 src="../assets/indexImg/step3.png"
                 alt="">

            <div class="textBox">
              <div>转岗培训</div>
              <div class="bc">

              </div>
              <div class="section-content">
                掌握新岗位技术业务知识和技能，取得新岗位上岗资格所进行的培训。
              </div>
            </div>

          </div>
          <div class="box1"
               @click="goList('0711')">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;"
                 src="../assets/indexImg/step4.png"
                 alt="">

            <div class="textBox">
              <div>党建培训</div>
              <div class="bc">

              </div>
              <div class="section-content">
                提高党建能力、增强党员意识更好的履行党从严治党责任。
              </div>
            </div>

          </div>
          <div class="box1"
               @click="goList('0702','070271')">
            <img style="width: 100%;height: 100%;position: absolute;top: 0;"
                 src="../assets/indexImg/step1.png"
                 alt="">
            <div class="textBox">
              <div>科学传播</div>
              <div class="bc">

              </div>
              <div class="section-content">
                旨在培养既懂科学知识,又善于科学传播的复合型人才,为公众提供准确、生动的科学普及服务。
              </div>
            </div>
          </div>
        </div>

        <div class="fastTrack"
             style="margin-top: 78px;">
          <div class="text">
            <img src="../assets/indexImg/title_lf.png"
                 alt="">
            专业继续教育<span>课程资源库</span>
            <img src="../assets/indexImg/title_rgt.png"
                 alt="">
          </div>
        </div>

        <div class="crl">
          <router-link to="/resourcesList">
            <div>
              <img src="../assets/indexImg/8-1.png"
                   alt="">
            </div>
          </router-link>
          <div class="section-right">
            <div class="slistBox"
                 v-for="item in recommendList"
                 :key="item.id"
                 @click="goresources(item)">
              <div>
                <img v-if="item.CImage && item.CImage !==null && item.CImage !== ''"
                     class="boxImg"
                     :src="item.CImage"
                     alt=""
                     @error="handleError">
                <div class="boxImg"
                     v-else
                     style="position: relative;display: flex;justify-content: center;align-items: center;">
                  <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -1;"
                       src="../assets/indexImg/noimg.png"
                       alt="">
                  <div style="font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                    {{ item.CName }}
                  </div>
                </div>
              </div>

              <div class="textBox"
                   style=" width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;padding: 0 10px;">
                {{ item.CName }}
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
    <div class="skillBox">
      <div style="display: flex;justify-content: center;">
        <div class="fastTrack">
          <div class="text">
            <img src="../assets/indexImg/title_lf.png"
                 alt="">
            <span>技能知识</span>提升继续教育服务
            <img src="../assets/indexImg/title_rgt.png"
                 alt="">
          </div>

        </div>
      </div>

      <div style="text-align: center;font-size: 16px;margin-top: 30px;color: #999;font-family: Regular;">
        提供全方位的网络教育解决方案，专业、周到和贴心的服务。
      </div>
      <div style="text-align: center;font-size: 16px;color: #999;margin-top:5px;font-family: Regular;">
        基于网络教育软件产品的定制开发和平台运营，配套的课件资源定制，多项网络教育软件产品和著作权。
      </div>

      <div class="skBn">
        <div style="width: 1200px;display: flex;">
          <div class="skLeft">
            <img src="../assets/indexImg/bg_skill.png"
                 alt="">
            <div class="leftTitle">
              <!-- {{ skill.codeName }} -->
              技能提升
            </div>

            <div style="display: flex;flex-wrap: wrap;">
              <div @click="oncode(item,index)"
                   :class="activeIndex == index ? 'skList active' : 'skList'"
                   v-for="(item,index) in codeList"
                   :key="item.id">
                {{ item.codeName }}
              </div>

            </div>
          </div>

          <div class="skCenter">
            <div>
              <img src="../assets/indexImg/newEntry.png"
                   alt="">
            </div>

            <div class="centerList">
              <div class="listBox"
                   v-for="(item,index) in skillClassList"
                   :key="index"
                   @click="goPay(item)">

                <div>

                  <img v-if="item.classImage && item.classImage !==null && item.classImage !== ''"
                       class="boxImg"
                       :src="item.classImage"
                       alt=""
                       @error="handleError">
                  <div class="boxImg"
                       v-else
                       style="position: relative;display: flex;justify-content: center;align-items: center;">
                    <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: -1;"
                         src="../assets/indexImg/noimg.png"
                         alt="">
                    <div style="font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                      {{ item.className }}
                    </div>
                  </div>
                </div>
                <div class="textBox">
                  {{ item.className }}
                </div>
              </div>

            </div>
          </div>

          <div class="skRight">
            <div class="fagui">
              <div class="fTitle">
                <div>
                  <img src="../assets/indexImg/icon_news.png"
                       alt="">
                </div>
                <div class="tips">政策法规</div>

                <div class="more"
                     @click="more">
                  MORE
                </div>
              </div>

              <div class="cotent">
                <p v-for="item in zcfgList"
                   :key="item.ID"
                   @click="tzgg(item)">
                  {{ item.TITLE }}
                </p>

              </div>
            </div>

            <div class="fagui"
                 style="margin-top: 40px;">
              <div class="fTitle">
                <div>
                  <img src="../assets/indexImg/icon_news.png"
                       alt="">
                </div>
                <div class="tips">行业资讯</div>

                <div class="more"
                     @click="more">
                  MORE
                </div>
              </div>

              <div class="cotent">
                <p v-for="item in hyzxList"
                   :key="item.ID"
                   @click="tzgg(item)">
                  {{ item.TITLE }}
                </p>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="banner"
         style="background: #fff;padding-bottom: 50px;">
      <div style="width: 1200px;">
        <div class="fastTrack">
          <div class="text">
            <img src="../assets/indexImg/title_lf.png"
                 alt="">
            <span>热门</span>推荐
            <img src="../assets/indexImg/title_rgt.png"
                 alt="">
          </div>
        </div>

        <div class="reList">
          <div class="reBox"
               v-for="item in classList"
               :key="item.CNo"
               @click="goShit(item)">
            <div>
              <img v-if="item.CImage && item.CImage !==null && item.CImage !== ''"
                   :src="item.CImage"
                   alt=""
                   @error="handleError">
              <div v-else
                   style="position: relative;display: flex;justify-content: center;align-items: center;width: 100%;height: 170px;">
                <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                     src="../assets/indexImg/noimg.png"
                     alt="">
                <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                  {{ item.CName }}
                </div>
              </div>

            </div>

            <div class="reText">
              <div class="text">
                {{ item.CName }}
              </div>

              <div class="botom">
                <div class="xue">
                  <div> {{ item.CCredithour }}学时</div>
                </div>

                <div class="botmBtn">
                  ¥ {{ item.CPrice }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <pcBottom></pcBottom>
    <Baywindow v-if="adPictureWindow !== '' "
               :adPictureWindow="adPictureWindow"
               :adPicture="adPicture" />

    <div v-if="adPicture !== ''&& !showTK"
         style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0, 0, 0, .7);z-index: 10000;">
      <div v-if="adPicture.jumpway == null"
           style="position: absolute;left: 50%;top: 50%;width: 500px;height: 585px;transform: translate(-50%,-50%);">
        <img style="width: 100%;height: 100%;"
             :src="adPicture.picUrl"
             alt="">
        <img @click="noShwo"
             style="position: absolute;top:-30px;right:-20px;cursor: pointer;width: 40px;z-index: 10001;"
             src="../assets/indexImg/closeIcon.png"
             alt="">
      </div>

      <div v-if="adPicture.jumpway !== null && adPicture.jumpway !== '02' "
           style="position: absolute;left: 50%;top: 50%;width: 500px;height: 585px;transform: translate(-50%,-50%);">
        <a 
        @click="goMessage(adPicture)"
           target="_blank"> <img style="width: 100%;height: 100%;"
               :src="adPicture.picUrl"
               alt=""></a>
        <img @click="noShwo"
             style="position: absolute;top:-30px;right:-20px;cursor: pointer;width: 40px;z-index: 10001;"
             src="../assets/indexImg/closeIcon.png"
             alt="">
      </div>

      <div v-if="adPicture.jumpway == '02'"
           style="position: absolute;left: 50%;top: 50%;width: 500px;height: 585px;transform: translate(-50%,-50%);">
        <a 
          :href="adPicture.picDesc"
           target="_blank"> <img style="width: 100%;height: 100%;"
               :src="adPicture.picUrl"
               alt=""></a>
        <img @click="noShwo"
             style="position: absolute;top:-30px;right:-20px;cursor: pointer;width: 40px;z-index: 10001;"
             src="../assets/indexImg/closeIcon.png"
             alt="">
      </div>

    </div>
    <flotRight />
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from './component/head.vue';
import pcBottom from './component/bottom.vue';
import { findIndexInfo, toHotClassByCode, exportEnrollData } from '../api/index';
import Baywindow from './component/Baywindow.vue';
import flotRight from '@/views/component/flotRight.vue';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    Baywindow,
    flotRight
  },
  data() {
    // 这里存放数据
    return {
      carouselList: [], //轮播图数据
      recommendList: [], //资源库课程列表
      skillClassList: {}, //技能提升分类
      activeIndex: null, //点击的样式
      classList: [], //热门的班级列表
      hyzxList: [], //行业资讯
      zcfgList: [], //政策法规
      tzggList: [], //通知公共
      codeList: [], //分类
      defaultImageUrl: require('../assets/images/noimg.png'),
      queryExl: {},
      adPictureWindow: '',
      adPicture: '',
      showTK: false
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    noShwo() {
      console.log(111);
      this.showTK = true;
    },
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    //浮动广告跳转
    goMessage(item) {
      console.log(item);
      this.$router.replace({
        path:'/message',
        query:{
          data:JSON.stringify(item)
        }
      })
    },
    toGetPicMessage(item) {
      console.log(item);
      if (item.jumpway == '02') {
        window.location.href = item.picDesc;
      } else {
        this.$router.push({
          path: '/zxDetil',
          query: {
            id: item.id
          }
        });
      }
    },
    //更多资讯
    more() {
      this.$router.push({
        path: 'tzgg'
      });
    },
    //通知公告点击
    tzgg(item) {
      console.log(item);
      this.$router.push({
        path: 'tzgg',
        query: {
          id: item.ID
        }
      });
    },
    //公需课
    goG() {
      this.$router.push('/gx');
    },
    goList(id, id2) {
      this.$router.push({
        path: '/lsitFs',
        query: {
          fCodeNo: id, //第一级
          codeNo: id2 //第二级
        }
      });
    },
    //课程资源库点击
    goresources(item) {
      console.log(item);
      this.$router.push({
        path: '/resources',
        query: {
          courseId: item.id
        }
      });
    },
    //技能提升点击
    oncode(item, index) {
      console.log(item);
      let query = {
        codeId: item.id,
        isSills: true,
        isChSkills: false,
        num: 6
      };
      //热门课程班级
      toHotClassByCode(query).then(res => {
        this.skillClassList = res.data.data.classList;
      });
      this.activeIndex = index;
    },
    getList() {
      // 首页数据
      findIndexInfo().then(res => {
        console.log(res.data.data);
        if (res.data.data == '01') {
          window.location.href = 'https://m.ahsjxjy.com';
          return false;
        }
        this.carouselList = res.data.data.picList;
        this.classList = res.data.data.hotCourseList;
        this.codeList = res.data.data.skillschSysCodeList;
        this.recommendList = res.data.data.recommendCourseList;
        this.hyzxList = res.data.data.hyzxList;
        this.zcfgList = res.data.data.zcfgList;
        this.tzggList = res.data.data.tzggList;
        this.skillClassList = res.data.data.skillClassList.classList;
        this.adPictureWindow = res.data.data.adPictureWindow;
        this.adPicture = res.data.data.adPicture;
      });
      // this.$store.state.m_user.dispatch('updateValue', 1);
    },
    //试听
    goShit(item) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          courseId: item.id
        }
      });
    },
    goPay(item) {
      this.$router.push({
        path: '/classDetail',
        query: {
          classId: item.id
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log(isMobile);
    if (isMobile) {
      window.location.href = 'https://m.ahsjxjy.com';
    }
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less">
/* //@import url(); 引入公共css类 */
.pos {
  height: 400px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
  font-family: Regular;
  .newsBox {
    position: absolute;
    width: 220px;
    right: -600px;
    top: 50px;
    z-index: 200;
    height: 300px;
    background: #fff;
    padding: 12px 0 0 0;
    z-index: 999;
    .newList {
      margin-top: 16px;
      padding: 0px 10px 0 16px;
      cursor: pointer;
      .listText {
        font-size: 14px;
        font-weight: 400;
        color: #999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        margin-left: 5px;
      }
    }
    .botmBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      height: 65px;
      line-height: 65px;
      background: #2878ff;
      color: #fff;
      width: 100%;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
.flex {
  display: flex;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.banner {
  font-family: Regular;
  display: flex;
  justify-content: center;
  .fastTrack {
    width: 1200px;
    display: flex;
    justify-content: center;
    margin-top: 43px;
    font-family: bold;
    .text {
      font-size: 32px;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 16px;
        margin: 0 15px;
      }
      span {
        color: #2878ff;
      }
    }
  }
  .fastList {
    display: flex;
    margin-top: 37px;

    .box1 {
      width: 285px;
      height: 160px;
      position: relative;
      margin-right: 20px;
      color: #fff;
      cursor: pointer;
      .textBox {
        padding: 33px 0 0 20px;
        font-size: 24px;
        font-family: bold;
        .bc {
          width: 50px;
          height: 4px;
          margin-top: 10px;
          background: #fff;
        }
        .section-content {
          margin-top: 10px;
          width: 170px;
          font-size: 12px;
          font-family: Regular;
          font-weight: 500;
          line-height: 20px;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      img {
        z-index: -1;
      }
    }
  }
  .crl {
    display: flex;
    margin-top: 50px;
    padding-bottom: 70px;
    .section-right {
      display: flex;
      width: 770px;
      height: 400px;
      flex-wrap: wrap;
      margin-left: 10px;
      .slistBox {
        width: 180px;
        height: 190px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09);
        margin-right: 15px;
        cursor: pointer;
        .boxImg {
          width: 100%;
          height: 149px;
        }
        .textBox {
          height: 40px;
          line-height: 40px;
          text-align: center;
        }
      }
      .slistBox:nth-child(4n) {
        margin-right: 0 !important;
      }
      .slistBox:nth-child(n + 5):nth-child(-n + 8) {
        margin-top: 12px;
      }
    }
  }
  .reList {
    margin-top: 37px;
    display: flex;
    flex-wrap: wrap;
    .reBox {
      width: 210px;
      height: 240px;
      margin-right: 37px;
      box-shadow: 0px 2px 10px #dddddd;
      border-radius: 6px;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 170px;
      }
      .reText {
        padding: 9px 10px 0;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
        }
        .botom {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          .xue {
            font-size: 12px;
            color: #bbbbbb;
            font-family: Regular;
          }
          .botmBtn {
            padding: 0 14px;
            height: 25px;
            line-height: 25px;
            border-radius: 17px;
            font-size: 14px;
            color: #fff;
            background: #ff5c10;
            margin-top: -6px;
            font-family: Regular;
          }
        }
      }
    }
    .reBox:nth-child(5n) {
      margin-right: 0 !important;
    }
    .reBox:hover {
      opacity: 0.7;
    }
  }
}
.skillBox {
  width: 100%;
  background: #f7f7f7;
  padding: 60px 0 72px 0;

  .fastTrack {
    width: 1200px;
    display: flex;
    justify-content: center;
    font-family: bold;
    .text {
      font-size: 32px;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 16px;
        margin: 0 15px;
      }
      span {
        color: #2878ff;
      }
    }
  }
  .skBn {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    padding-bottom: 20px;
    .skLeft {
      width: 245px;
      height: 618px;
      position: relative;
      padding: 32px 10px 0 20px;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .leftTitle {
        font-family: Medium;
        position: relative;
        font-size: 28px;
        color: #2878ff;
        margin-bottom: 32px;
        font-family: bold;
        z-index: 2;
      }
      .skList {
        font-family: Medium;
        position: relative;
        z-index: 2;
        height: 30px;
        line-height: 30px;
        padding: 0 17px;
        border: 1px solid #2878ff;
        border-radius: 15px;
        color: #2878ff;
        font-size: 14px;
        cursor: pointer;
        margin: 0 10px 15px 0;
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .active {
        font-family: Medium;
        position: relative;
        z-index: 2;
        background: #2878ff !important;
        color: #fff !important;
      }
      .skList:hover {
        font-family: Medium;
        position: relative;
        z-index: 2;
        background: #2878ff !important;
        color: #fff !important;
      }
    }
    .skCenter {
      padding-left: 20px;
      font-family: Regular;
      background: #fff;
      .centerList {
        margin-top: 17px;
        width: 670px;
        display: flex;
        flex-wrap: wrap;
        .listBox {
          width: 210px;
          height: 224px;
          margin-right: 20px;
          cursor: pointer;
          img {
            width: 100%;
            height: 174px;
          }
          .textBox {
            font-size: 16px;
            color: #333;
            margin: 13px 0;
          }
        }
        .listBox:nth-child(3n) {
          margin-right: 0 !important;
        }
        .listBox:hover {
          opacity: 0.7;
        }
      }
    }
    .skRight {
      background: #fff;
      width: 245px;
      padding: 20px 20px 0 20px;
      .fagui {
        .fTitle {
          display: flex;
          img {
            width: 15px;
            height: 24px;
          }
          .tips {
            font-family: Regular;
            font-size: 22px;
            margin-left: 10px;
          }
          .more {
            margin-left: 50px;
            color: #ccc;
            padding-top: 7px;
            cursor: pointer;
          }
        }
        .cotent {
          p {
            font-size: 14px;
            color: #999;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          p:hover {
            opacity: 0.7 !important;
          }
        }
      }
    }
  }
}
</style>